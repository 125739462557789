exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meet-our-team-tsx": () => import("./../../../src/pages/meet-our-team.tsx" /* webpackChunkName: "component---src-pages-meet-our-team-tsx" */),
  "component---src-pages-qualifications-tsx": () => import("./../../../src/pages/qualifications.tsx" /* webpackChunkName: "component---src-pages-qualifications-tsx" */),
  "component---src-pages-rto-access-to-records-tsx": () => import("./../../../src/pages/rto/access-to-records.tsx" /* webpackChunkName: "component---src-pages-rto-access-to-records-tsx" */),
  "component---src-pages-rto-complaints-and-appeals-tsx": () => import("./../../../src/pages/rto/complaints-and-appeals.tsx" /* webpackChunkName: "component---src-pages-rto-complaints-and-appeals-tsx" */),
  "component---src-pages-rto-credit-transfer-tsx": () => import("./../../../src/pages/rto/credit-transfer.tsx" /* webpackChunkName: "component---src-pages-rto-credit-transfer-tsx" */),
  "component---src-pages-rto-learner-support-services-tsx": () => import("./../../../src/pages/rto/learner-support-services.tsx" /* webpackChunkName: "component---src-pages-rto-learner-support-services-tsx" */),
  "component---src-pages-rto-recognition-of-prior-learning-rpl-tsx": () => import("./../../../src/pages/rto/recognition-of-prior-learning-rpl.tsx" /* webpackChunkName: "component---src-pages-rto-recognition-of-prior-learning-rpl-tsx" */),
  "component---src-pages-rto-tsx": () => import("./../../../src/pages/rto.tsx" /* webpackChunkName: "component---src-pages-rto-tsx" */),
  "component---src-pages-rto-unique-student-identifier-usi-tsx": () => import("./../../../src/pages/rto/unique-student-identifier-usi.tsx" /* webpackChunkName: "component---src-pages-rto-unique-student-identifier-usi-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

